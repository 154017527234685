

// import mintbtn from "./mintbtn.js"
import Navbar from "./mintpagenavbar.js";


import React, { Component } from "react";
import "react-slideshow-image/dist/styles.css";
import Mintbtn from "./mintbuttonmtn (1).js";



import "./App.css"
import { Zoom } from "react-reveal";

function Home() {




    return (
        <div className="mainclass  ">
            <div id="main" className="container-fluid ">
                <Navbar />

                <hr className="line"></hr>

                {/* <div className=" banner2Container">
                    <div className="banner234 ">
                    </div>


                </div> */}
                <div className="container">
                    <div className="row">
                        <div className="col-md-1"></div>
                        <div className="col-md-3 classone classonesilevr ">
                            <h5>MTM
                            </h5>
                            <h2 className="packagename"> SILVER</h2>
                            <h5>
                                With your Silver NFT, you will gain access to the Silver
                                Collection of clothing from our  website,along with a 12% discount,
                                one-time Made-to-Measure services and 1 ticket in our Giveaway,
                                once the entire NFT collection is sold.
                                <br></br><br></br><br></br><br></br>

                            </h5>
                            <a href="http://silvermint.metasensys.io/">
                                <button type="button" class="btn-lg btn btn-outline-secondary">Mint Silver</button>
                            </a>
                        </div>
                        <br></br><br></br>
                        <div className="col-md-3 classone classonegold">
                            <h5>STYLING
                            </h5>

                            <h2 className="packagename">GOLD</h2>
                            <h5>
                                With your Gold NFT, you will gain access to the Gold Collection of clothing from our website, along with a 18% discount, one-time personalized Styling for the clothes you order and 2 tickets in our Giveaway, once the entire NFT collection is sold.
                                <br></br><br></br>                                <br></br>

                            </h5>
                            <a href="http://goldmint.metasensys.io/">
                                <button type="button" class="btn-lg btn btn-outline-success">Mint Gold</button>
                            </a>
                        </div>                        <br></br><br></br>

                        <div className="col-md-3 classone classonediamond">
                            <h5>CUSTOM EXPERIENCE</h5>
                            <h2 className="packagename"> DIAMOND</h2>
                            <h5>
                                With your Platinum NFT,  along with a 24% discount, your own one-time Custom Experience in designing and choosing your outfit and 3 tickets in our Giveaway, once the entire NFT collection is sold. Furthermore, our diamond members will have special benefits to access the mystic power of the next Metasensys NFT collection.

                            </h5>
                            <a href="http://diamondmint.metasensys.io/">
                                {/* <a href="/MintDiamond"> */}

                                <button type="button" class="btn-lg btn btn-outline-info">Mint Diamond</button>
                            </a>
                        </div>
                        <div className="col-md-1"></div>

                    </div>

                </div>
            </div>
            <div className="container-fluid paddingremovetop  abvgt ">


            </div>





        </div >

    );
}

export default Home;
